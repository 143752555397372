
//首页Vuex
export default {
    namespaced:true,//开启命名空间
    state:{
        
    },
    mutations: {

    },
    actions: {

    }
  }