<template>
    <div class="nav">
        <el-menu 
            :default-active     = "activeIndex" 
            background-color    = "#909399"
            text-color          = "#fff"
            active-text-color   = "#ffd04b"
            class="el-menu-demo" 
            mode="horizontal" 
            @select="handleSelect"
        >
            <!-- 一级导航栏 -->
            <el-menu-item index="1" style="margin:0 0 0 20%">
                <i class="el-icon-s-promotion icon_color"></i>
                    首页
            </el-menu-item>
            <el-submenu index="2">
                <template slot="title">
                    <i class="el-icon-location icon_color"></i>
                    分类
                </template>
                <el-menu-item index="2-1">选项1</el-menu-item>
                <el-menu-item index="2-2">选项2</el-menu-item>
                <el-menu-item index="2-3">选项3</el-menu-item>
            </el-submenu>
            <el-menu-item index="4"><a href="https://baidu.com" target="_blank">百度</a></el-menu-item>
            <!-- 搜索框 -->
            <!-- <el-input placeholder="请输入内容" v-model="search_text">
                <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input> -->
            <!-- 头像 -->
            <el-avatar 
                class="home_avatar"
                size="number"
                alt='avatar'
                src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            >
            </el-avatar>
        </el-menu>
    </div>
</template>

<script type='text/javascript'>
 export default {
   name: 'HomeNav',
   props: {
   },
   components: {
   },
   data () {
     return {
        activeIndex: '1',
        NavData:[]
     }
   },
   methods: {
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
      }
    },
   mounted() {

   },
   watch: {
   },
   computed: {
   },
   filters: {
   }
 }
</script>


<style scoped lang='css'>
.nav{
    width: 100%;
    height: 60px;
    line-height: 60px;
}
.icon_color{
    color:#409EFF
}
/* 搜索框 */
.home_input{
   width: 60px;
   height: 30px;
}
/* 头像 */
.home_avatar{
    margin-left:50px;
    border: none;
    vertical-align: middle;
}
</style>