<template>
  <div id="bannerBox">
    <el-carousel 
        :height=bannerHeight
    >
      <el-carousel-item v-for="(item,index) in imagers" :key="index">
        <h3 class="small">
            <a :href="item.to">
                <img :style="BannerImg"  :src="item.url">
            </a>
        </h3>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script type='text/javascript'>
export default {
  name: "HomeBanner",
  props: {},
  components: {},
  data() {
    return {
        bannerHeight:'350px',
        BannerImg:null,
        imagers:[
            {
               name:'测试1', 
               url:'http://oss.tlms.top/uploads/20221123/Fnf6422hoLRuhV4mScN3dCp8EQ0f.png',
               to:'null' 
            },
            {
               name:'测试2', 
               url:'http://oss.tlms.top/uploads/20221123/Fnf6422hoLRuhV4mScN3dCp8EQ0f.png',
               to:'null' 
            },
            {
               name:'测试3', 
               url:'http://oss.tlms.top/uploads/20221123/Fnf6422hoLRuhV4mScN3dCp8EQ0f.png',
               to:'null' 
            }
        ]
    };
  },
  methods: {
  },
  mounted() {
      this.BannerImg = {
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        height: '100%',
        width: '100%',
        position: 'fixed',
        overflow: 'scroll',
      }
  },
  watch: {},
  computed: {},
  filters: {},
};
</script>
<style scoped lang='scss'>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>