<template>
  <div>
    <div>
      <h4>关于网站</h4>
      <img />
      <el-avatar :src="avatarUrl" alt="头像" fit="fill"> </el-avatar>
      <div>
        <span>Excalibur</span><br />
        <span
          >测试文本测试文本测试文本测试文本测试文本测试文本测试文本
          测试文本测试文本测试文本测试文本测试文本
          测试文本测试文本测试文本测试文本测试文本
        </span>
      </div>
      <!-- 联系方式 -->
      <div>
        <el-tooltip
          v-for="(item, index) in contactInformation"
          :key="index"
          class="item"
          effect="dark"
          :content="item.msg"
          placement="bottom"
        >
          <!--  :content="item.msg" -->
          <el-button id="elseButton" type="info" size="small">占</el-button>
        </el-tooltip>
      </div>

      <!-- 网页信息 -->
      <div>
          
      </div>
    </div>
  </div>
</template>

<script type='text/javascript'>
export default {
  name: "HomeUserInfo",
  props: {},
  components: {},
  data() {
    return {
      contactInformation: [
        {
          ico: "el-icon-message",
          to: "www.baidu.com",
          msg: "QQ",
        },
        {
          ico: "el-icon-message",
          to: "www.baidu.com",
          msg: "VX",
        },
        {
          ico: "el-icon-message",
          to: "www.baidu.com",
          msg: "Github",
        },
      ],
      avatarUrl:
        "https://oss.tlms.top/uploads/20221121/FsO-KENfGpE9JyPBJ-pnEr9OoAKU.png",
    };
  },
  methods: {},
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
};
</script>
<style scoped lang='scss'>
// 头像大小
.el-avatar {
  width: 60px !important;
  height: 60px !important;
}

//其他按钮样式
#elseButton {
  background: rgba($color: #000000, $alpha: 0.1);
}
</style>