<template>
    <div :style="background"><!-- 背景 -->
        <div v-if="IsMasking" :style="masking"></div><!-- 蒙版 -->
    </div>
</template>

<script type='text/javascript'>
 export default {
   name: 'Background',
   props: {
   },
   components: {
   },
   data () {
     return {
        IsMasking : true, //是否打开背景蒙版
        bgImg : null,
     }
   },
   methods: {
   },
   //载入前(css载入)
   beforeMount (){
      this.bgImg ='http://oss.tlms.top/uploads/20221123/Fnf6422hoLRuhV4mScN3dCp8EQ0f.png';
   },
   //载入后(接口请求数据返回渲染)
   mounted() {
   },
   watch: {
   },
   computed: {
       //背景图片
       background:function(){
           let background = {
                background: 'url('+ this.bgImg +') no-repeat top',
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
                height: '100%',
                width: '100%',
                position: 'fixed',
                overflow: 'scroll',
                zIndex: -99999
            }
           return background;
       },
       //蒙版
       masking:function(){
           let masking ={
               position:'fixed',
               top:'0',
               left:'0',
               zIndex:-99998,
               height: '100%',
               width: '100%',
               overflow:'scroll',
               background:'rgba(7,17,27,.4)',
               backdropFilter:'blur(5px)'
           }
           return masking;
       }
   },
   filters: {
   }
 }
</script>
<style scoped lang='css'>
  #particles-js {
    position: fixed;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    z-index: -99998;
  }

  ::-webkit-scrollbar {
    display: none;
  }
</style>