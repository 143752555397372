<template>
  <div id="mainBox">
    <el-container>
      <el-container>
        <!-- 头部 -->
        <el-header id="headerBox">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item>
            <el-breadcrumb-item>活动列表</el-breadcrumb-item>
            <el-breadcrumb-item>活动详情</el-breadcrumb-item>
          </el-breadcrumb>
        </el-header>
        <!-- 主体列表 -->
        <el-main id="listBox">
          <HomeBanner></HomeBanner>
        </el-main>
        <!-- 尾部 -->
        <el-footer> 尾部 </el-footer>
      </el-container>
      <!-- 右侧边 -->
      <el-aside id="userInfoBox">
        <HomeUserInfo></HomeUserInfo>
      </el-aside>
    </el-container>
  </div>
</template>
<script type='text/javascript'>
//轮播图
import HomeBanner from '@/components/Home/Banner'
import HomeUserInfo from '@/components/Home/UserInfo'

export default {
  name: "HomeMain",
  props: {},
  components: {
     HomeBanner,
     HomeUserInfo
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
};
</script>
<style scoped lang='scss'>
// 背景盒子
#mainBox {
  width: 68%;
  min-height: 51rem;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); //投影
  backdrop-filter: contrast(50%) blur(2px); //模糊
  border-radius: 4px;
}
//面包屑盒子
#headerBox {
  width: 100%;
  height: 2rem !important;
  background: rgba($color: #ffffff, $alpha: 0.6);
  border-radius: 4px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
//重写斜杠顏色
::v-deep {
  .el-breadcrumb__separator {
     color: #606266;
  }
}
//列表盒子
#listBox {
  min-width: 73%;
  background: rgba($color: #ffffff, $alpha: 0.6);
  border-radius: 4px;
}
//个人信息盒子
#userInfoBox {
  width: 30%;
  height: 100%;
  background: rgba($color: #ffffff, $alpha: 0.6);
  border-radius: 4px;
  margin-left: 5px;
}
</style>