import Vue from 'vue';
import App from './App.vue';

//引入element-ui组件库
import ElementUI from 'element-ui';
//引入element全部样式
import 'element-ui/lib/theme-chalk/index.css';

//使用element ui插件库
Vue.use(ElementUI);

//引入Vuex的store
import store from './store';
//引入路由
import router from './pages';

//引入Bootstrap
// import bootstrap from 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.css'

//引入animate动画库
import 'animate.css';

// 关闭生产环境报错
Vue.config.productionTip = false,


new Vue({
  render: h => h(App),
  store,
  router,
  // bootstrap
}).$mount('#app')

