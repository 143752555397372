import { Service } from "./Service";

//------首页请求------

//首页背景请求
export function getHomeBackground(parent){
    return Service({
        method:'GET',
        url:'home/background',
        parent:parent,
    })
}

//页尾信息
export function getHomeFootMsg(parent){
    return Service({
        method:'GET',
        url:'home/foot',
        parent:parent,
    })
}