//引入路由组件
import VueRouter from "vue-router";

//引入Luyou 组件

//创建Router组件
export default new VueRouter(
    {
        path:'H'
    }
)