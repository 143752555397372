//vuex入口文件

//引入vue核心库
import Vue from 'vue';

//引入vuex
import Vuex from 'vuex';

//应用vue
Vue.use(Vuex);

//引入数据
import home from './home'


//暴露数据
export default new Vuex.Store({
    home
})
