<template>
    <el-row
      type="flex"
      align="middle"
      justify="center"
    >
      <!-- 页脚信息 -->
      <span v-for="(value,items) in msgArr" :key="items">
        <el-divider
          direction="vertical" 
          v-if="items !==0"
          ></el-divider>
        <el-link 
            :underline="false"  
            :href="value.url"
            type="info" 
            target="_blank"
            >
            {{value.title}}
          </el-link>
      </span>
    </el-row>
</template>

<script type='text/javascript'>
 import {
      getHomeFootMsg 
    } from '@/Request.js';
 export default {
   name: 'HomeFoot',
   props: {
   },
   components: {
   },
   data () {
     return {
        msgArr:[]
     }
   },
   methods: {
   },
   mounted() {
      //页尾信息
      getHomeFootMsg().then(Data=>{
        this.msgArr = Data.data
      })

   },
   watch: {
   },
   computed: {
   },
   filters: {
   }
 }
</script>
<style scoped lang='css'>
#foot{
    width: 100%;
    height: 60px;
    background-color: black;
    color: beige;
}
</style>