//组件引入
import  Axios  from "axios";
import { Message,Loading } from "element-ui";

// 请求地址
const ConfgBaseURL = "https://tp.tlms.top";   //正式域名 
// const ConfgBaseURL = "http://www.blog.cn";      //测试域名
let is_loadingInstance = false;                 //是否开启加载蒙版
let loadingInstance    = null; 
//请求夹带参数
let   Headers = {
  'Content-Type' : 'application/x-www-form-urlencoded;charset=UTF-8',
  'token'        : 'text'
};

//封装请求
export const Service = Axios.create({
    //请求超时时间
    timeout: 5000,
    //请求地址
    baseURL: ConfgBaseURL,
    //请求方式
    method:'PSOT',
    //携带请求参数
    headers:Headers,               
})
//添加请求拦截器
Service.interceptors.request.use(config=>{
  //加载蒙版--开始--
  if(is_loadingInstance){
    loadingInstance = Loading.service({
      lock: true,
      text: '正在加载中...'
    })
  }
  //查询缓存token
  let token = localStorage.getItem('token');
  if(token){
    config.headers.token = token;
  }
  return config
})
// 添加响应拦截器
Service.interceptors.response.use(response => {
  //加载蒙版--结束--
  if(is_loadingInstance){
    loadingInstance.close()
  }
  //请求成功返回数据
  // console.log(response)
  return response.data
}, error => {
  console.log('响应拦截器: error', error)
  const msg = error.Message !== undefined ? error.Message : ''
  //报错弹窗
  Message({
    message: '网络错误' + msg,
    type: 'error',
    duration: 3 * 1000
  })
  if (error.response) {
    switch (error.response.status) {
      case 401:
        localStorage.removeItem('Authorization');
        localStorage.removeItem('Cookie');
        this.$router.push('/login');
    }
  }
  loadingInstance.close()
  return Promise.reject(error)
})