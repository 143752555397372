<template>
  <div>
    <HomeNav></HomeNav>
    <Background></Background>
    <HomeMain></HomeMain> 
    <HomeFoot id="foot"></HomeFoot>
  </div>
</template>

<script>

//背景
import Background from '@/components/Background'
//导航栏
import HomeNav from '@/components/Home/Nav'
//主区块
import HomeMain from '@/components/Home/Main'
//页脚
import HomeFoot from '@/components/Home/Foot'

export default {
  name: 'App',
  components: {
    HomeNav,
    HomeFoot,
    Background,
    HomeMain
  },
  data () {
     return {
     }
   },
  //方法
  methods:{
  },
  //初始化
  mounted() {
    
  }
}
</script>

<style>
  #AppBackground{
      background:url("http://qiniu.tlms.top/uploads/20221123/Fnf6422hoLRuhV4mScN3dCp8EQ0f.png");
      width:100%;
      height:100%;
      position:fixed;
      background-size:100% 100%;
  }
  html{
   outline: none;                     /*清除input边框 */
   box-sizing: border-box;            /*只能往里缩 */
   list-style: none;                  /*清除列表的点 */
   text-decoration: none !important;  /*清除a标签的下划线 */
   vertical-align: middle;            /*让图片和文字在中间基线居中 */
   /* background-color: rgb(0, 41, 41); */
   /* background-image:'url('+var(--background)+')'; */
  }
  body {
      margin: 0;                        /*初始化宽高 */
      padding: 0;                       /*初始化宽高 */
  }
  /* 页脚 */
  #foot{
    width: 100%; 
    position:relative;
    overflow:hidden;
    min-height: 100%;
    /* position: fixed;
    bottom: 0px; */
  }
</style>
